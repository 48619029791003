import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CompetitionBlock from "../components/MainStats/CompetitionBlock"
import LastUpdated from "../components/LastUpdated/LastUpdated"
import SelectMenuNav from "../components/Utilities/SelectMenuNav"

export const query = graphql`
  query($slug: Date!) {
    allSheetMessiCalYearStats(filter: { year: { eq: $slug } }) {
      edges {
        node {
          year
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          xa
          fkc
          xgc
          xac
        }
      }
    }
    allSheetRonaldoCalYearStats(filter: { year: { eq: $slug } }) {
      edges {
        node {
          year
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          xa
          fkc
          xgc
          xac
        }
      }
    }
  }
`

function CalYearSeason(props) {

  const path = props.location.pathname;

  const mdata = props.data.allSheetMessiCalYearStats.edges
  const rdata = props.data.allSheetRonaldoCalYearStats.edges

  const year = mdata[0].node.year

  return (
    <Layout>
      <SEO
        title={`${year} Calendar Year Goals and Stats - Messi vs Ronaldo`}
        canonicalPath={path === "/calendar-year-stats/" ? '/calendar-year-stats/2024/' : `${path}/`}
        description={path === '/calendar-year-stats/2024/' || path === '/calendar-year-stats/' ? (
          `Who has scored more goals in ${year}? Messi or Ronaldo? What about hat-tricks, free kicks and penalties? Find all of these ${year} stats and more on messivsronaldo.app!`
        ) : (
          `Who scored more goals in ${year}? Messi or Ronaldo? What about hat-tricks, free kicks and penalties? Find all of these ${year} stats and more on messivsronaldo.app!`
        )}
      />

      <h1 className="sr-only">{year} <span>Calendar Year Goals and Stats - Messi vs Ronaldo</span></h1>

      <SelectMenuNav
          current={year}
          parentPath="calendar-year-stats"
        />


      <CompetitionBlock competition="Club and Country" type="both" mdata={mdata} rdata={rdata} perfmsg="[comps] only" />

      <CompetitionBlock competition="Club" type="club" mdata={mdata} rdata={rdata} perfmsg="[comps] only" />

      <CompetitionBlock competition="Country" type="int" mdata={mdata} rdata={rdata} />

      {mdata.filter(d => d.node.competition === "League").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "League").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="League" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "MLS Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "MLS Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="MLS Cup" type="club" subtitle="MLS Cup Playoffs" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Champions League").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Champions League").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Champions League" compTitle="UEFA Champions League" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Champions League Qualifying").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Champions League Qualifying").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Champions League Qualifying" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Continental Tournament").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Continental Tournament").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Continental Tournament" subtitle="AFC Champions League / CONCACAF Champions Cup" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Europa League").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Europa League").map(d => d.node.apps) > 0 ? (
          <CompetitionBlock competition="Europa League" type="club" mdata={mdata} rdata={rdata} />
        ) : ''}

      {mdata.filter(d => d.node.competition === "UEFA Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "UEFA Cup").map(d => d.node.apps) > 0 ? (
          <CompetitionBlock competition="UEFA Cup" type="club" mdata={mdata} rdata={rdata} />
        ) : ''}

      {mdata.filter(d => d.node.competition === "Domestic Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Domestic Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Domestic Cup" type="club" subtitle="Copa del Rey, Coppa Italia, FA Cup, Coupe de France, Saudi King Cup, US Open Cup" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "League Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "League Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="League Cup" type="club" subtitle="English Football League Cup" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Leagues Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Leagues Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Leagues Cup" type="club" subtitle="USA/Mexican Cup Competition" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Arab Club Champions Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Arab Club Champions Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Arab Club Champions Cup" type="club" subtitle="Cup Competition with teams from Arab states" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Domestic Super Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Domestic Super Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Domestic Super Cup" type="club" subtitle="Supercopa de Espana, Supercoppa Italiana, FA Community Shield, Trophée des Champions, Saudi Super Cup, Campeones Cup" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "UEFA Super Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "UEFA Super Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="UEFA Super Cup" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Club World Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Club World Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Club World Cup" type="club" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "World Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "World Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="World Cup" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Copa America / Euros").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Copa America / Euros").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Copa America / Euros" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "World Cup Qualifiers").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "World Cup Qualifiers").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="World Cup Qualifiers" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Euro Qualifiers").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Euro Qualifiers").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Euro Qualifiers" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Confederations Cup").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Confederations Cup").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Confederations Cup" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "UEFA Nations League").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "UEFA Nations League").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="UEFA Nations League" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "Finalissima").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "Finalissima").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="Finalissima" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      {mdata.filter(d => d.node.competition === "International Friendlies").map(d => d.node.apps) > 0 || rdata.filter(d => d.node.competition === "International Friendlies").map(d => d.node.apps) > 0 ? (
        <CompetitionBlock competition="International Friendlies" type="int" mdata={mdata} rdata={rdata} />
      ) : ''}

      <LastUpdated type="Stats" />
      
    </Layout>
  )
}

export default CalYearSeason
